import { useMutation } from '@apollo/client'
import React from 'react'
import { createSessionBatchMutation } from '../../../apollo/query/user'
import Modal from '../../UI/Modal'
import { addSuccessAlert } from '../../../utils/helpers/alerts'
import { handleApolloError } from '../../../utils/errors'

const HelpSigninTraineesModal = ({ id, appendTo, userIds }) => {
  const [createSessionBatch] = useMutation(createSessionBatchMutation, {
    onError: handleApolloError,
  })

  const submitHandler = () => {
    createSessionBatch({
      variables: {
        userIds,
      },
    })
    addSuccessAlert({
      title: 'The export of codes is being made',
      subtitle: 'You are notified by email when finished',
      icon: 'progress',
      timeout: false,
    })
  }

  return (
    <Modal
      id={id}
      headerText="Help trainees sign in"
      subtitle="Receive an export with a code for each selected trainee"
      appendTo={appendTo}
      footerText="Exports are made in the background. You are notified by email when finished."
      submitHandler={submitHandler}
      cancelHandler={() => {
        setTimeout(() => setSelectedValue(0), 200)
      }}
      useDefaultButtons></Modal>
  )
}

export default HelpSigninTraineesModal
