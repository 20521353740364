import React, { useContext, useState } from 'react'
import Modal from '../../../../UI/Modal'
import { useMutation } from '@apollo/client'
import {
  publishTestScenarioMutation,
  updateScenarioTestersMutation,
} from '../../../../../apollo/query/scenarios'
import { handleApolloError } from '../../../../../utils/errors'
import TestMemberList from './TestMemberList'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'

const TestModalForm = ({
  editing,
  testScenario,
  setTestScenario,
  archivedScenarios,
  setArchivedScenarios,
}) => {
  const { scenario } = useContext(ScenarioEditorContext)
  const [memberList, setMemberList] = useState([])
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const [publishTestScenario] = useMutation(publishTestScenarioMutation, {
    onError: handleApolloError,
    onCompleted: ({
      publishTestScenario: { testScenario: newTestScenario },
    }) => {
      setTestScenario({
        ...newTestScenario,
        attemptsCount: 0,
        testers: memberList,
      })

      setArchivedScenarios([
        ...(testScenario ? [testScenario] : []),
        ...archivedScenarios,
      ])
      setMessage('')
      setMemberList([])
      setLoading(false)
      $('#test-modal').foundation('open')

    },
  })

  const [updateScenarioTesters] = useMutation(updateScenarioTestersMutation, {
    onError: handleApolloError,
    onCompleted: ({ updateScenarioTesters: { testScenario } }) => {
      setTestScenario({
        ...testScenario,
        testers: memberList,
      })
      setLoading(false)
      $('#test-modal').foundation('open')
    },
  })

  const handleConfirm = () => {
    setLoading(true)
    if (editing) return handleEditing()

    handlePublish()
  }

  const handlePublish = () => {
    const variables = {
      id: scenario.id,
      testerIds: memberList.map((member) => member.id),
      message,
    }
    publishTestScenario({
      variables,
    })
  }

  const handleEditing = () => {
    updateScenarioTesters({
      variables: {
        id: scenario.id,
        testerIds: memberList.map((member) => member.id),
      },
    })
  }

  return (
    <>
      <Modal
        id="new-test-modal"
        appendTo="#scenario-modals"
        headerText={editing ? 'Edit test members' : 'New test version'}
        closeOnEscape
        overflowY="visible"
        footerText={
          <>
            Members that can test this scenario
            <br />
            will be notified by email
          </>
        }
        buttons={
          <div className="flex-container align-middle">
            <button
              className="button hollow secondary text-normal text-bold mr-1"
              data-open="test-modal">
              Cancel
            </button>
            <button
              className={`button primary text-normal text-bold ${loading ? 'o-button--loading' : ''}`}
              onClick={handleConfirm}
              data-open="test-modal"
              disabled={loading}>
              Confirm
            </button>
          </div>
        }>
        <form className="mt-3">
          {!editing && (
            <>
              <label>What can be tested in this version?</label>
              <textarea
                rows="5"
                name="message"
                onChange={({ target: { value } }) => setMessage(value)}
                value={message}></textarea>
            </>
          )}

          <label>Select members that can test this version</label>
          <TestMemberList
            memberList={memberList}
            setMemberList={setMemberList}
            editing={editing}
            testScenario={testScenario}
          />
        </form>
      </Modal>
    </>
  )
}
export default TestModalForm
