import { useLazyQuery } from '@apollo/client'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { FiX } from 'react-icons/fi'
import SearchableDropdown from '../../../../UI/Menu/SearchableDropdown'
import { getUsersQuery } from '../../../../../apollo/query/user'
import { ScenarioEditorContext } from '../../../context/ScenarioEditorProvider'
import useCurrentUser from '../../../../../hooks/useCurrentUser'
import YouLabel from '../../../../UI/Labels/YouLabel'

const TestMemberList = ({
  memberList,
  setMemberList,
  testScenario,
  editing,
}) => {
  const { scenario } = useContext(ScenarioEditorContext)
  const [_currentUser] = useCurrentUser()
  const parsedData = (member) => {
    return {
      value: `${member.name} ${member.email}`,
      render: (
        <div className="flex-container align-middle">
          {member.name}{' '}
          <span className="text-stable-dark">• {member.email}</span>
          {member.id === currentUser?.id && <YouLabel className="ml-1" />}
        </div>
      ),
      email: member.email,
      name: member.name,
      id: member.id,
      defaultSelected:
        member.id === currentUser.id ||
        !!testScenario?.testers?.find((tester) => tester.id === member.id),
    }
  }
  const [allMembers, setAllMembers] = useState([])
  const [show, setShow] = useState(false)
  const currentUser = useMemo(
    () =>
      _currentUser
        ? {
            ..._currentUser,
            name: `${_currentUser.firstName} ${_currentUser.lastName}`,
          }
        : {},
    [_currentUser]
  )

  const [getMembers, { loading }] = useLazyQuery(getUsersQuery, {
    variables: {
      role: 'MEMBER',
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ users }) => {
      setAllMembers(users.map(parsedData))
      const selectedMembers = editing
        ? users.filter((user) =>
            testScenario?.testers?.find((tester) => tester.id === user.id)
          )
        : (testScenario?.testers ?? [currentUser])
      setMemberList(selectedMembers.map(parsedData))
    },
  })

  const removeMember = (id) => {
    const newMemberList = memberList.filter((member) => member.id !== id)
    setMemberList(newMemberList)
  }

  const addMember = (member) => {
    setMemberList([...memberList, member])
  }

  const showDetailMemberList = () => {
    if (!memberList.length)
      return (
        <div className="border-light border-radius flex-container align-middle pt-1-5 pb-1-5 pl-2 pr-2">
          <p className="text-italic mb-0">No members selected yet...</p>
        </div>
      )
    return (
      <ul className="o-modal__video--list">
        {memberList.map((member) => {
          return (
            <li key={member.id} style={{ padding: '0 20px' }}>
              <div
                style={{
                  flexGrow: '1',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}>
                <span style={{ flexGrow: '1' }}>{member.render}</span>
                {member.id !== currentUser.id && (
                  <div className="flex-container align-middle text-stable-dark">
                    <span
                      className="cursor-pointer"
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                      onClick={() => removeMember(member.id)}>
                      <FiX style={{ marginLeft: '10px' }} size="16" />
                    </span>
                  </div>
                )}
              </div>
            </li>
          )
        })}
      </ul>
    )
  }

  useEffect(() => {
    if (testScenario?.testers?.length && editing) getMembers()
    else if (_currentUser)
      setMemberList(
        testScenario?.testers
          ? testScenario.testers.map(parsedData)
          : [parsedData(currentUser)]
      )
  }, [scenario.id, editing, _currentUser, testScenario])

  return (
    <div>
      {showDetailMemberList()}
      <div className="mt-1 mb-1">
        <button
          className="button hollow secondary text-bold mb-1 mt1"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setShow(true)
            if (!allMembers.length) getMembers()
          }}>
          <FaPlus className="mr-1" /> Select member
        </button>
        {show && (
          <SearchableDropdown
            placeholder="Search member by name or email..."
            clickHandler={(user) => {
              addMember(user)
              setShow(false)
            }}
            hideHandler={() => setShow(false)}
            loading={loading}
            notFoundTitle="No members found"
            data={allMembers.filter(
              (member) => !memberList.find((user) => user.id === member.id)
            )}
          />
        )}
      </div>
    </div>
  )
}
export default TestMemberList
