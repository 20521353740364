import React from 'react'

const YouLabel = ({ className = '' }) => {
  return (
    <span
      className={`o-label o-label--stable-lighter text-bold text-stable-dark ${className}`}>
      YOU
    </span>
  )
}

export default YouLabel
