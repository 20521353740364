import React, { Suspense, useContext, useEffect, useMemo } from 'react'
import ScenarioEditorProvider, {
  ScenarioEditorContext,
} from '../components/FlowV2/context/ScenarioEditorProvider'
import withApollo from '../hooks/withApollo'
import { Route, Link, Routes, useLocation } from 'react-router-dom'
import {
  EDITOR_TABS,
  useEditorActiveTab,
} from '../components/FlowV2/helpers/editorHelper'
import PublishModal from '../components/FlowV2/components/FlowEditor/Publish/PublishModal'
import { ReactFlowProvider } from 'reactflow'
import FlowEditor from '../components/FlowV2/tabs/FlowEditor'
import TestModal from '../components/FlowV2/components/FlowEditor/Publish/TestModal'
import DataTooltip from '../components/UI/DataTooltip'
import useCurrentUser from '../hooks/useCurrentUser'
import LoadingSpinner from '../components/UI/LoadingSpinner'

const ScenarioEditor = () => {
  const activeTab = useEditorActiveTab()
  const { scenario } = useContext(ScenarioEditorContext)
  const location = useLocation()
  const [, currentClient] = useCurrentUser()

  useEffect(() => {
    // Add a trailing slash to the URL if it's missing
    const regex = /^\/scenarios\/\d+$/
    if (regex.test(window.location.pathname)) {
      window.history.replaceState(null, null, window.location.pathname + '/')
    }
  }, [])

  if (!scenario) return null

  return (
    <div id="scenario-editor">
      <div className="c-sub-menu" style={{ marginTop: '-2.5rem' }}>
        <div className="grid-container fluid flex-container align-middle align-justify">
          <div className="c-sub-menu__inner">
            <div className="c-sub-menu__filter">
              <p>{scenario.name}</p>
            </div>
            <ul className="c-sub-menu__list">
              {EDITOR_TABS.map((tab) => (
                <li
                  key={tab.key}
                  className={`c-sub-menu__list-item ${
                    activeTab.key === tab.key ? 'active' : ''
                  }`}>
                  <Link to={`/${tab.key}`} className="c-sub-menu__list-link">
                    {tab.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex-container align-middle">
            <button
              className="o-button o-button--royal o-button--auto-width text-normal text-bold mr-1"
              data-open="test-modal">
              Test
            </button>
            {currentClient?.canPublishScenarios ? (
              <button
                className="o-button o-button--success o-button--auto-width text-normal text-bold"
                data-open="publish-modal">
                Publish
              </button>
            ) : (
              <DataTooltip
                position="bottom"
                title="You do not have publishing slots open to publish this scenario. Contact the owner of this workspace or go to settings to increase the number of publishing slots">
                <button
                  className="o-button o-button--success o-button--auto-width text-normal text-bold"
                  disabled>
                  Publish
                </button>
              </DataTooltip>
            )}
          </div>
        </div>
      </div>
      <ReactFlowProvider>
        <div
          hidden={EDITOR_TABS.some(
            (tab) => tab.isRoute && location.pathname.includes(tab.key)
          )}>
          <FlowEditor />
        </div>
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            {EDITOR_TABS.filter((tab) => tab.isRoute).map((tab) => (
              <Route
                key={tab.key}
                path={`${tab.key}/*`}
                element={tab.content}
              />
            ))}
          </Routes>
        </Suspense>
      </ReactFlowProvider>
      <div id="scenario-modals"></div>

      {/* Please do not remove the following wrapper div */}
      <div>
        <PublishModal />
      </div>

      <TestModal />
    </div>
  )
}

const ScenarioEditorWrapper = ({ scenarioId }) => {
  return (
    <ScenarioEditorProvider scenarioId={scenarioId}>
      <ScenarioEditor scenarioId={scenarioId} />
    </ScenarioEditorProvider>
  )
}

export default withApollo(ScenarioEditorWrapper)
