import React, { useState, useRef } from 'react'
import {
  FaQuestionCircle,
  FaSlidersH,
  FaFileVideo,
  FaCut,
} from 'react-icons/fa'
import DataTooltip from '../../../UI/DataTooltip'
import RangeInput from '../../../UI/Form/RangeInput'
import {
  convertResolution,
  convertMilliSeconds,
} from '../../../../utils/format'
import { moveCameraToPosition } from '../../../Editor/helpers/controls'
import useShowVideoEditor from '../../../FlowV2/hooks/useShowVideoEditor'
import useAddUpdateScene from '../../../FlowV2/hooks/useAddUpdateScene'
import { setNode } from '../../../FlowV2/helpers/nodeHelper'
import { useReactFlow } from 'reactflow'

const MediaSettingsTab = ({ selectedNode }) => {
  const reactFlow = useReactFlow()
  const scene = selectedNode.data
  const medium = scene.video
  const [showWarning, setShowWarning] = useState(false)
  const [warningMessage, setWarningMessage] = useState('')
  const northOffsetInputRef = useRef()
  const showVideoEditor = useShowVideoEditor()
  const { updateScene } = useAddUpdateScene()

  if (!medium) return null

  const getMediaInfo = () => {
    const data = [
      { name: 'File name', item: scene.video.name },
      {
        name: 'Type',
        item: (
          <span>{scene.video.stereoscopic ? 'Stereo 360°' : 'Mono'} video</span>
        ),
      },
      { name: 'Length', item: convertMilliSeconds(scene.video.duration) },
      { name: 'Framerate', item: `${scene.video.fps} FPS` },
      {
        name: 'Resolution',
        item: convertResolution(scene.video.width),
      },
    ]

    return data.map((i, index) => (
      <li
        key={index}
        className={index < data.length - 1 ? 'mb-1' : ''}
        style={{ listStyle: 'none' }}>
        <span>{i.name}</span>
        <span className="float-right">{i.item}</span>
      </li>
    ))
  }

  const getTrimInfo = () => {
    if (!medium.trimEnabled) return null
    return (
      <div className="border-light-top pt-1-5 pb-1-5 pl-2 pr-2 flex-container align-middle align-justify text-normal">
        <div className="flex-container align-middle">
          <span className="o-label o-label--stable text-stable-dark mr-1">
            <FaCut />
          </span>
          Video is trimmed
        </div>
        <div>
          {convertMilliSeconds(medium.trimStart * 1000 || 0)} -{' '}
          {convertMilliSeconds(medium.trimEnd * 1000 || medium.duration)}
        </div>
      </div>
    )
  }

  const validateNorthOffsetInput = (value) => {
    const regex = /^([-]?[1-9]\d*|0|-0)$/
    if (value) {
      if (!value.match(regex)) {
        if (!value.match(/^([-]?1-9])/)) {
          setWarningMessage('Value is not valid, cannot start with a leading 0')
        } else {
          setWarningMessage('Value is not valid, please use whole degrees only')
        }
        setShowWarning(true)
        return false
      }
    }
    if (Number(value) < -180 || Number(value) > 180) {
      setWarningMessage('Value should be between -180˚ and 180˚')
      setShowWarning(true)
      return false
    }
    setShowWarning(false)
    return true
  }
  const handleNorthOffset = (e) => {
    if (Number(e.target.value) === scene.northOffset) {
      return false
    }
    const isValid = validateNorthOffsetInput(e.target.value)
    if (isValid) {
      updateScene({
        ...scene,
        northOffset: Math.floor(e.target.value),
      })
      if (showVideoEditor) moveCameraToPosition(0, -e.target.value, true)
      setShowWarning(false)
      setWarningMessage('')
    }
  }

  const getNorthOffsetCoords = () => {
    return (
      <div className="text-normal flex-container align-middle">
        <input
          className="c-video__input--north-offset no-arrows"
          type="number"
          min="-180"
          max="180"
          onFocus={(e) => e.target.select()}
          ref={northOffsetInputRef}
          defaultValue={scene.northOffset}
          onBlur={handleNorthOffset}
          onKeyUp={(e) => {
            if (e.key === 'Enter') handleNorthOffset(e)
          }}
        />
        <span className="ml-1">degrees</span>
      </div>
    )
  }

  return (
    <div className="tab p-3">
      <div id="controls" className="border-light border-radius">
        <h3 className="p-2 border-light-bottom text-normal text-bold flex-container align-middle mb-0">
          <FaSlidersH className="mr-1" /> Video controls
        </h3>
        <div className="pt-1-5 pb-1-5 pl-2 pr-2 flex-container align-justify align-middle text-normal w-100">
          <div className="flex-container align-middle">
            <div className="o-switch mr-1">
              <input
                type="checkbox"
                name="enableNorthOffset"
                id="enableNorthOffset"
                className="o-switch__input"
                checked={scene.northOffsetEnabled || false}
                onChange={() => {
                  updateScene({
                    ...scene,
                    northOffsetEnabled: !scene.northOffsetEnabled,
                    ...(!scene.northOffsetEnabled ? { northOffset: 0 } : {}),
                  })
                }}
              />
              <label
                htmlFor="enableNorthOffset"
                className="o-switch__paddle"></label>
            </div>
            Set initial orientation
            <DataTooltip
              position="right"
              title="Setting the initial orientation determines where trainees look at when they first enter a scene.">
              <FaQuestionCircle className="text-stable ml-0-5" />
            </DataTooltip>
          </div>
          {scene.northOffsetEnabled && getNorthOffsetCoords()}
        </div>
        {showWarning && (
          <div className="pl-2 pb-1 pr-2">
            <label
              className={`o-slider__label text-assertive  mt-0 o-slider__invalid`}>
              {warningMessage}
            </label>
          </div>
        )}
        {scene.northOffsetEnabled && (
          <div className="pl-2 pr-2 border-light-top">
            <RangeInput
              minValue={0}
              maxValue={360}
              showLabels={false}
              value={180 + scene.northOffset}
              noBackground={true}
              setValue={(v, save) => {
                const value = Math.floor(v - 180)
                if (showVideoEditor) {
                  moveCameraToPosition(0, -v - 180, true)
                  setNode(reactFlow, {
                    ...selectedNode,
                    data: {
                      ...scene,
                      northOffsetEnabled: scene.northOffsetEnabled,
                      northOffset: value,
                    },
                  })
                }
                northOffsetInputRef.current.value = value
                setShowWarning(false)
                if (save) {
                  updateScene({
                    ...scene,
                    northOffsetEnabled: scene.northOffsetEnabled,
                    northOffset: value,
                  })
                }
              }}
            />
          </div>
        )}
      </div>

      <div id="media-information" className="border-light border-radius mt-2">
        <h3 className="p-2 border-light-bottom text-normal text-bold flex-container align-middle mb-0">
          <FaFileVideo className="mr-1" />
          File information
        </h3>
        <div className="list text-normal pt-1-5 pb-1-5 pr-2 pl-2 mb-0">
          {getMediaInfo()}
        </div>
        {getTrimInfo()}
      </div>
    </div>
  )
}

export default MediaSettingsTab
