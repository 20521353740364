import 'aframe'
import 'aframe-rounded'
import '../../Editor/Video/Elements/partials/aframe-text-bubble'
import '../../Editor/Video/Elements/partials/aframe-button'
import '../../Editor/Video/Elements/partials/aframe-mpc'
import '../../Editor/Video/Elements/partials/aframe-scene-settings'
import '../../Editor/Video/Elements/partials/aframe-collider-check'
import '../../Editor/Video/Elements/partials/aframe-hideable-element'
import '../../Editor/Video/Elements/partials/aframe-collision-box'

import React, { useEffect, useRef, useState, useContext } from 'react'
import { useQuery } from '@apollo/client'
import NorthOffsetLine from '../../Editor/Video/Elements/partials/NorthOffsetLine'
import { SceneEditorContext } from '../../Editor/SceneEditorContext'
import Information from '../../Editor/Video/Elements/Information'
import Hotspot from '../../Editor/Video/Elements/Hotspot'
import Direction from '../../Editor/Video/Elements/Direction'
import MultipleChoice from '../../Editor/Video/Elements/MultipleChoice'
import { VideoContext } from '../../Editor/VideoContext'
import { moveCameraToPosition } from '../../Editor/helpers/controls'
import floorGrid from './assets/grid.png'
import skyGradient from './assets/background-sky-1.png'
import Delay from '../../../utils/helpers/Delay'
import { MediumContext } from '../../Media/Modal/MediumContext'
import { getMediumQuery } from '../../../apollo/query/media'
import LoadingSpinner from '../LoadingSpinner'
import useVideoPlayer from '../../Editor/useVideoPlayer'
import Nadir from './Nadir'

const Player = ({ mediumID, fov = 70, scenarioData }) => {
  const [sceneState] = useContext(SceneEditorContext)
  const [state, setState] = useContext(VideoContext)
  const {
    medium: [medium, setMediumContext],
    currentVideoRef,
  } = useContext(MediumContext)
  const { setShowElements } = useVideoPlayer()
  const [currentVideo, setCurrentVideo] = useState(null)
  const stateRef = useRef(sceneState)
  const rotation = '0 -90 0'

  const { loading } = useQuery(getMediumQuery, {
    variables: {
      id: mediumID,
    },
    onCompleted: (d) => {
      const n = { ...d.media[0] }
      setMediumContext(n)
    },
  })

  useEffect(() => {
    // update state ref on state change
    stateRef.current = sceneState
  }, [sceneState])

  const loadedHandler = () => {
    const { trimEnabled, trimStart } = medium
    setState((s) => ({
      ...s,
      video: currentVideoRef.current,
    }))
    setCurrentVideo(currentVideoRef.current)
    currentVideoRef.current.currentTime = trimEnabled ? trimStart : 0
    if (sceneState.northOffsetEnabled)
      moveCameraToPosition(0, -sceneState.northOffset || 0, true)
  }

  const pauseHandler = () => {
    setShowElements(true)
  }
  const loopHandler = () => {
    if (!stateRef.current.videoLoopEnabled) {
      return
    }

    if (medium.trimEnabled) {
      currentVideoRef.current.currentTime =
        stateRef.current.videoLoop + Number(medium.trimStart)
      currentVideoRef.current.play()
    } else {
      currentVideoRef.current.currentTime = stateRef.current.videoLoop
      currentVideoRef.current.play()
    }
  }

  const timeUpdateHandler = () => {
    if (
      currentVideoRef.current.currentTime === 0 ||
      (medium.trimEnabled &&
        currentVideoRef.current.currentTime === medium.trimStart)
    ) {
      return setShowElements(true)
    }
    if (medium.trimEnabled) {
      return setShowElements(
        Number(currentVideoRef.current.currentTime) -
          Number(medium.trimStart) >=
          Number(stateRef.current.questionFadeIn)
      )
    }
    return setShowElements(
      currentVideoRef.current.currentTime >=
        Number(stateRef.current.giveHintAfter) +
          Number(stateRef.current.questionFadeIn)
    )
  }
  useEffect(() => {
    // Ensure the video shows an image in VR mode
    if (medium) {
      if (
        currentVideoRef?.current &&
        currentVideoRef.current !== currentVideo
      ) {
        currentVideoRef.current.addEventListener('pause', pauseHandler, true)
        currentVideoRef.current.addEventListener('ended', loopHandler, true)
        currentVideoRef.current.addEventListener(
          'ended-on-trim',
          loopHandler,
          true
        )
        currentVideoRef.current.addEventListener(
          'loadedmetadata',
          loadedHandler,
          true
        )
        currentVideoRef.current.addEventListener(
          'timeupdate',
          timeUpdateHandler,
          true
        )
      }
    }
  }, [medium, sceneState, currentVideo])

  if (loading)
    return (
      <Delay timeout="1000">
        <LoadingSpinner noHeight dotsOnly />
      </Delay>
    )

  const elements = () => {
    const id = `${sceneState.kind}-${sceneState.id}`
    switch (sceneState.kind) {
      case 'INFORMATION':
        return <Information id={id} scene={sceneState} state={state} />
      case 'HOTSPOT':
        return <Hotspot id={id} scene={sceneState} state={state} />
      case 'DIRECTION':
        return <Direction id={id} scene={sceneState} state={state} />
      case 'MPC':
        return <MultipleChoice id={id} scene={sceneState} state={state} />
      default:
        return null
    }
  }

  const noVideoPlayer = () => (
    <Delay>
      <a-assets>
        <img
          id="floor"
          src={floorGrid}
          alt="no video"
          crossOrigin="anonymous"
        />
        <img
          id="sky"
          src={skyGradient}
          alt="sky gradient"
          crossOrigin="anonymous"
        />
      </a-assets>
      <a-sky id="a-sky" radius="32" material="src:#sky; shader: flat;" />
      <a-entity
        id="floor"
        position="0 -7.6 0"
        geometry={`
          primitive: cylinder;
          width: 50;
          height: 0.1;
          radius: 50;
          `}
        material={`
          src: #floor;
          repeat: 50 50;
          shader: flat;
          scale: 0.5 0.5 0.5;
          `}
      />
    </Delay>
  )

  const player = () => {
    return (
      <div className="video-element--container">
        <a-scene
          scene-settings
          raycaster="objects: .clickable"
          xr-mode-ui="enterVRButton: #fullScreenVRButton">
          <a id="fullScreenVRButton" />
          {/* Assets */}
          <a-assets>
            <img
              crossOrigin="anonymous"
              src={scenarioData.client.logoVrUrl}
              id="client-vr-logo"
              alt=""
            />
            <video
              ref={currentVideoRef}
              id="video"
              src={sceneState.video.play4kUrl}
              crossOrigin="anonymous"></video>
          </a-assets>
          {/* Camera */}
          <a-camera
            wasd-controls-enabled="false"
            id="a-camera"
            fov={fov}
            position="0 0 0"
            look-controls="reverseMouseDrag: true"></a-camera>
          {/* Background video */}
          {sceneState.video.play4kUrl ? (
            <a-videosphere
              id="a-videosphere"
              src="#video"
              rotation={rotation}></a-videosphere>
          ) : (
            noVideoPlayer()
          )}

          {sceneState.northOffsetEnabled && <NorthOffsetLine />}
          {/* Elements */}
          {elements()}

          {!scenarioData.hideNadir && <Nadir />}
        </a-scene>
      </div>
    )
  }
  return (
    <div id="videoplayer">
      {loading && 'loading...'}
      {player()}
    </div>
  )
}
export default Player
