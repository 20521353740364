import { useMemo } from 'react'
import { useReactFlow } from 'reactflow'

const useSelectedNode = () => {
  const reactFlow = useReactFlow()
  const nodes = reactFlow.getNodes()
  const selectedNode = useMemo(
    () => nodes.find((n) => n.selected && Number(n.id)),
    [nodes]
  )

  return selectedNode
}

export const useAllSelectedNodes = () => {
  const reactFlow = useReactFlow()
  const nodes = reactFlow.getNodes()
  const selectedNodes = useMemo(
    () => nodes.filter((n) => n.selected && Number(n.id)),
    [nodes]
  )

  return selectedNodes ?? []
}
export default useSelectedNode
